<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<template>
  <div class="q-py-sm rounded-borders">
    <q-card
      class="q-pa-md chb-card bg-white shadow-7"
      v-if="!processLoading && displayLogin()"
    >
      <div class="q-pa-sm q-mb-sm row justify-around items-center">
        <TenantInfo :tenantLoginInfo="tenantLoginInfo" />
      </div>
      <q-form @submit.prevent.stop="onSubmitLogin">
        <div class="q-pa-md" v-if="isShowResetPassword == false">
          <div class="column">
            <q-input
              filled
              clearable
              color="primary"
              ref="usernameRef"
              v-model="username"
              :label="$t('message.userNameLbl')"
              autocomplete="username"
              autofocus
              lazy-rules
              :disable="isTfaRequired"
              :rules="[
                val => (val && val.length > 0) || $t('message.usernameReqMsg')
              ]"
            >
              <template v-slot:prepend>
                <q-icon name="person" />
              </template>
            </q-input>
            <q-input
              filled
              clearable
              color="primary"
              ref="passwordRef"
              v-model="password"
              :label="$t('message.passwordLbl')"
              :type="isPwd ? 'password' : 'text'"
              autocomplete="current-password"
              lazy-rules
              :disable="isTfaRequired"
              :rules="[
                val => (val && val.length > 0) || $t('message.passwordReqMsg')
              ]"
            >
              <template v-slot:prepend>
                <q-icon name="lock" />
              </template>
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
            <q-input
              v-if="isTfaRequired"
              filled
              clearable
              color="primary"
              ref="tfaCodeRef"
              v-model="tfaCode"
              :label="$t('message.tfaCodeLbl')"
              autofocus
              lazy-rules
              :rules="[
                val =>
                  (val && val.length == 6) ||
                  $t('message.tfaReqMsg', {
                    1: username
                  })
              ]"
            >
              <template v-slot:prepend>
                <q-icon name="mark_email_unread" />
              </template>
            </q-input>
            <div class="self-end" v-if="isTfaRequired">
              <q-btn
                flat
                no-caps
                color="primary"
                :label="$t('message.cancelLbl')"
                size="md"
                align="right"
                @click="
                  isTfaRequired = false;
                  tfaCode = null;
                "
              />
            </div>
            <transition
              appear
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <q-btn
                no-caps
                rounded
                icon="send"
                type="submit"
                :label="$t('message.loginBtnLbl')"
                class="q-my-md full-width"
                color="primary"
              />
            </transition>
            <div class="self-end" v-if="!isTfaRequired">
              <q-btn
                flat
                color="primary"
                :label="$t('message.forgotPasswordLbl')"
                size="sm"
                align="right"
                @click="onSwitchToResetPassword"
              />
            </div>
            <!-- Display error message -->
            <!-- <q-alert
              v-if="loginError"
              icon="warning"
              :style="{ color: 'red' }"
              class="q-mt-md"
            >
              {{ loginError }}
            </q-alert> -->
          </div>
        </div>
        <div class="q-pa-md" v-if="isShowResetPassword">
          <div class="column">
            <q-input
              filled
              clearable
              color="primary"
              v-model="email"
              ref="emailRef"
              :label="$t('message.emailLbl')"
              autofocus
              lazy-rules
              :rules="[
                val =>
                  (val && val.length > 0 && emailRegEx.test(val)) ||
                  $t('message.emailReqMsg')
              ]"
            >
              <template v-slot:prepend>
                <q-icon name="person" />
              </template>
            </q-input>
            <transition
              appear
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <q-btn
                no-caps
                rounded
                icon="send"
                type="submit"
                :label="$t('message.reqNewPasswordLbl')"
                class="q-my-md full-width"
                color="primary"
              />
            </transition>
            <div class="self-end">
              <q-btn
                flat
                color="primary"
                :label="$t('message.backLbl')"
                size="sm"
                align="right"
                @click="onSwitchToLogin"
              />
            </div>
          </div>
        </div>
      </q-form>
    </q-card>
    <div class="row full-width justify-center bg-primary" v-else>
      <div style="max-width: 35%">
        <a href="https://www.pitchyou.de" target="_blank"
          ><img src="../assets/pitchyou-logo.png" class="img-fluid"
        /></a>
      </div>
    </div>
    <LoadingIndicator
      :showLoading="processLoading"
      v-if="loadingIndicatorVisible"
    ></LoadingIndicator>
  </div>
</template>

<script  setup lang="ts">
//import { Vue } from 'vue-property-decorator';
import { ref, onMounted as vueOnMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { QInput, useQuasar } from 'quasar';
import { useI18n } from 'vue-i18n';


import {
  LoginRequest,
  ResetPasswordRequest,
  TenantLoginInfo
} from '@/store/models';
import userModule from '@/store/modules/userModule';
import TenantInfo from '@/components/TenantInfo.vue';
import { Tenant } from '../store/models';
import tenantModule from '@/store/modules/tenantModule';
import { showErrorMsgToast, showSuccessMsgToast } from '@/common';
import { SessionStorage } from 'quasar';
import { homedir } from 'os';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

    const { t, locale } = useI18n();
    const onMounted = vueOnMounted;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const $q = useQuasar();

    const username = ref('');
    const password = ref('');
    const email = ref('');
    const isPwd = ref(true);
    const tenant = ref<Tenant | null>(null);
    const tenantLoginInfo = ref<TenantLoginInfo | null>(null);
    const isShowResetPassword = ref(false);
    const processLoading = ref(true);
    const loadingIndicatorVisible = ref(true);
    const superAdminCred = ref<boolean | null>(false);
    const isTfaRequired = ref(false);
    const tfaCode = ref<string | null>(null);
    const hideLogin = ref(false);
    const loginError =  ref<string>('');
    const emailRef = ref();
    const usernameRef = ref();
    const passwordRef = ref();
    const tfaCodeRef = ref<QInput | undefined>();
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    onMounted(async () => {
    processLoading.value = true;
    try {
      await loadTenantLoginInfo();
      processLoading.value = false;
      if (tenantLoginInfo.value) {
        hideLogin.value = tenantLoginInfo.value.hideLogin;
      }
    } catch (error) {
      loadingIndicatorVisible.value = false;
      showErrorMsgToast(

        t('message.unknownTenantErrorToastMessage') as string
      );
    }
    });


    const loadTenantInfo = async () => {
    tenant.value = tenantModule.tenantInfo || null;
    if (!tenant.value) {
      await tenantModule.loadTenantInfo();
      tenant.value = tenantModule.tenantInfo;
    }
    };

    const loadTenantLoginInfo = async () => {
    tenantLoginInfo.value = tenantModule.tenantLoginInfo || null;
    if (!tenantLoginInfo.value) {
      await tenantModule.loadTenantLoginInfo();
      tenantLoginInfo.value = tenantModule.tenantLoginInfo;
    }
    };

    const onSwitchToResetPassword = () => {
    isShowResetPassword.value = !isShowResetPassword.value;
    const usernameRefValue = usernameRef.value;
    const passwordRefValue = passwordRef.value;
    usernameRefValue.resetValidation();
    passwordRefValue.resetValidation();
    };

    const onSwitchToLogin = () => {
    isShowResetPassword.value = !isShowResetPassword.value;
    const emailRefValue = emailRef.value;
    emailRefValue.resetValidation();
    };

  const onSubmitLogin = async () => {
    if (isShowResetPassword.value) {
      const emailRefValue = emailRef.value;
      emailRefValue.validate();
      if (!emailRefValue.hasError) {
        try {
          const resetPasswordRequest: ResetPasswordRequest = {
            userName: email.value,
          };

          await userModule.performResetPassword(resetPasswordRequest);

          onSwitchToLogin();
          email.value = '';
          showSuccessMsgToast( t('message.resPwEmailSent') as string);
        } catch (error) {
          console.log(error);
          showLoginError();
        }
      }
    } else {
      const usernameRefValue = usernameRef.value;
      const passwordRefValue = passwordRef.value;
      usernameRefValue.validate();
      passwordRefValue.validate();

      if (!usernameRefValue.hasError && !passwordRefValue.hasError) {
        const loginRequest: LoginRequest = {
          username: username.value,
          password: password.value,
          browserLanguage: locale.value,
          tfaCode: tfaCode.value || undefined,
        };
        try {
          userModule.resetUser();
          await userModule.login(loginRequest);

          if (userModule.isLoggedIn) {
            await loadTenantInfo();
            let user = userModule.loggedInUser;
            if (user && user.tfaRequired) {
              isTfaRequired.value = true;
              isPwd.value = true;
              showSuccessMsgToast( t('message.tfaCodeSentByEmailMsg', { 1: username.value }) as string);
              return;
            } else {
              isTfaRequired.value = false;
            }

              superAdminCred.value = await userModule.isCred('SUPERADMIN');

            if (user && user.preferredLanguageCode) {
              locale.value = user.preferredLanguageCode;
            }

              if (route.params && route.params.origin && route.params.origin.length) {
              console.log('route.params');
              router.push({
                name: route.params.origin as string,
                params: route.params,
              });
            } else if (route.query && route.query.origin && route.query.origin.length) {
              router.push({
                name: route.query.origin as string,
                query: route.query,
              });
            } else if (superAdminCred.value) {
              router.push({ name: 'dashboard' });
            } else if (tenant.value && tenant.value.reportsOnlyAccess) {
              router.push({ name: 'reports' });
            } else if (tenant.value && tenant.value.messageCenterStandalone) {
              router.push({ name: 'messagecenter' });
            } else {
              router.push({ name: 'dashboard' });
            }
          }
        } catch (error: any) {
          if (error.message.includes('TFA_CODE_ERROR')) {
            showErrorMsgToast(t('message.failedTfaCodeError', { 1: username.value }) as string);
          }
          else if (error.message.includes('AUTHORIZATION_ERROR')) {
            // loginError.value = t(
            //     'message.failedUnauthorizedError'
            // ) as string;
            showErrorMsgToast(t('message.failedUnauthorizedError') as string);
          }
          else {
            console.log(error);
            showLoginError();
          }
        }
      }
    }
};


// Define asynchronous delay function
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const showLoginError = () => {
  $q.notify({
    color: 'pink-7',
    icon: 'warning',
    position: 'top',
    message: t('message.failedLoginMsg') as string,
  });
};

const displayLogin = () => {
  if (tenantModule.isSuperAdminATSLoginAllowed) {
    return true;
  } else {
    return !hideLogin.value;
  }
};


</script>
