<template>
  <q-card style="min-width: 45vw; max-width: 80vw">
    <q-card-section class="row items-center">
      <div class="text-h6">
        {{ $t('message.manageTagsLbl') }}
      </div>
      <q-space />
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-separator />
    <q-card-section>
      <q-input
        dense
        filled
        color="primary"
        v-model="tagsFilterSearch"
        :label="$t('message.reportsFilterSearchLbl')"
        @update:model-value="onTagsFilterSearchChange"
        ><template v-slot:append>
          <q-icon
            name="close"
            @click="
              tagsFilterSearch = '';
              onTagsFilterSearchChange();
            "
            class="cursor-pointer"
          />
        </template> </q-input
    ></q-card-section>
    <q-card-section style="height: 43vh" class="scroll">
      <div>
        <div
          v-for="candidateTagOption in candidateTagOptions"
          :key="candidateTagOption.groupName"
        >
          <q-expansion-item
            expand-separator
            icon="group"
            :label="getGroupTitle(candidateTagOption)"
            header-class="text-bold text-primary"
          >
            <!-- <template v-slot:header>
                <q-item-section avatar>
                  <q-icon name="group" color="primary" text-color="white" />
                </q-item-section>

                <q-item-section>{{
                  getGroupTitle(candidateTagOption)
                }}</q-item-section>
                <q-item-section side>
                  <q-btn
                    icon="add"
                    flat
                    dense
                    @click.stop="addExistingGroup(candidateTagOption)"
                  >
                    <q-tooltip> Add existing tag </q-tooltip>
                  </q-btn>
                </q-item-section>
              </template> -->
            <q-card>
              <q-card-section>
                <q-list>
                  <q-item
                    v-for="tag in candidateTagOption.candidateTagDtos"
                    :key="tag.value"
                  >
                    <q-item-section>
                      <div class="row full-width">
                        <q-input
                          v-model="tag.label"
                          dense
                          outlined
                          style="min-width: 30vw"
                          @update:model-value="tagValueChanged(tag)"
                        />
                        <q-btn
                          icon="save"
                          color="primary"
                          class="q-mr-md"
                          flat
                          :disabled="tag.disableSave"
                          @click="updateTagName(tag)"
                          ><q-tooltip>{{
                            $t('message.updateContactLbl')
                          }}</q-tooltip></q-btn
                        >
                        <q-btn
                          icon="delete"
                          color="primary"
                          class="q-mr-md"
                          flat
                          @click="
                            showTagDeleteConfirmation = true;
                            selectedTagToDelete = tag;
                          "
                          ><q-tooltip>{{
                            $t('message.deleteLbl')
                          }}</q-tooltip></q-btn
                        >
                      </div>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card-section>
            </q-card>
          </q-expansion-item>
          <q-separator />
        </div>
      </div>
    </q-card-section>
    <q-separator />
    <div class="row row full-width">
      <div class="row q-pa-md" v-show="showAddTag">
        <q-input
          v-model="newTagValue"
          dense
          outlined
          label="New Tag"
          style="min-width: 25vw"
        />
        <q-btn
          icon="group_add"
          color="primary"
          flat
          @click="
            (showAddGroupsDialog = true),
              (isApplyButtonDisabled = !isSelectedGroup())
          "
          ><q-tooltip>{{ $t('message.selectGroupTooltip') }}</q-tooltip></q-btn
        >
        <q-btn
          icon="save"
          color="primary"
          flat
          :disabled="disableSave()"
          @click="createNewTag"
          ><q-tooltip>{{ $t('message.saveLbl') }}</q-tooltip></q-btn
        >
        <q-btn
          icon="cancel"
          color="primary"
          flat
          @click="
            showAddTag = false;
            disableAddTag = false;
            newTagValue = '';
          "
          ><q-tooltip>{{ $t('message.cancelLbl') }}</q-tooltip></q-btn
        >
      </div>
      <q-space />
      <q-card-actions align="right" class="q-pa-md">
        <q-btn
          icon="add"
          :label="$t('message.addCandidateTagButtonLbl')"
          color="primary"
          :disabled="disableAddTag"
          @click="
            showAddTag = true;
            disableAddTag = true;
          "
        />
      </q-card-actions>
    </div>
    <LoadingIndicator :showLoading="showLoadingIndicator"></LoadingIndicator>
    <q-dialog v-model="showTagDeleteConfirmation" persistent>
      <q-card style="min-width: 40vw">
        <q-card-section class="row items-center">
          <q-avatar icon="delete_forever" color="negative" text-color="white" />
          <span class="q-ml-sm">{{
            $t('message.tagDeleteConfirmationMsg')
          }}</span>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            :label="$t('message.deleteConfirmationNoBtnLbl')"
            color="primary"
            v-close-popup
          />
          <q-btn
            flat
            :label="$t('message.deleteConfirmationYesBtnLbl')"
            color="primary"
            @click.prevent="deleteTagName"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showAddGroupsDialog" persistent>
      <q-card style="min-width: 40vw; max-width: 80vw">
        <q-card-section class="row items-center">
          <div class="text-h6">
            {{ $t('message.selectGroupTooltip') }}
          </div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-separator />
        <q-card-section style="max-height: 40vh" class="scroll">
          <q-input
            class="q-pb-lg"
            dense
            filled
            color="primary"
            v-model="groupFiltersearch"
            :label="$t('message.reportsFilterSearchLbl')"
            @update:model-value="onGroupFilterSearchChange"
            ><template v-slot:append>
              <q-icon
                name="close"
                @click="
                  groupFiltersearch = '';
                  onGroupFilterSearchChange();
                "
                class="cursor-pointer"
              />
            </template>
          </q-input>
          <q-list>
            <q-item v-for="group in userGroups" :key="group.id" clickable>
              <q-item-section>
                <div
                  class="row full-width items-center"
                  @click.prevent="onClickUserGroup(group)"
                >
                  <div>
                    <q-checkbox
                      v-model="group.selected"
                      @update:model-value="selectUserGroup(group)"
                    />
                  </div>
                  <div>
                    <q-item-label>{{ group.itemName }}</q-item-label>
                  </div>
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            v-close-popup
            no-caps
            @click="deselectGroup"
            color="primary"
            icon="cancel"
            :label="$t('message.cancelLbl')"
          />
          <q-btn
            v-close-popup
            no-caps
            class="text-white"
            color="primary"
            :disabled="isApplyButtonDisabled"
          >
            {{ $t('message.apply') }}
          </q-btn>
        </q-card-actions>
        <q-separator />
      </q-card>
    </q-dialog>
  </q-card>
</template>
<script setup lang="ts">
import { showErrorMsgToast, showSuccessMsgToast } from '@/common';
import {
  CandidateTagCreateAssignGroupRequest,
  FilterResponseDto,
  GroupCandidateTagDto,
  TagMangementDto,
  User
} from '@/store/models';
import interviewRecruiterModule from '@/store/modules/interviewRecruiterModule';
import userModule from '@/store/modules/userModule';
import { findIndex } from 'lodash-es';
import LoadingIndicator from './LoadingIndicator.vue';

import { useQuasar } from 'quasar';
import { defineProps, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const router = useRouter();
const store = useStore();
const $q = useQuasar();
const { t, locale } = useI18n();

const loadTags = defineProps(['loadTags']);

const candidateTagOptions = ref<GroupCandidateTagDto[]>([]);
const filteredTagOptions = ref<GroupCandidateTagDto[]>([]);
const copyTagOptions = ref<GroupCandidateTagDto[]>([]);
const tagsFilterSearch = ref<string>('');
const showAddTag = ref<boolean>(false);
const disableAddTag = ref<boolean>(false);
const newTagValue = ref<string>('');
const showLoadingIndicator = ref<boolean>(false);
const showTagDeleteConfirmation = ref<boolean>(false);
const selectedTagToDelete = ref<TagMangementDto | null>(null);
const showAddGroupsDialog = ref<boolean>(false);
const groupFiltersearch = ref<string>('');
const userGroups = ref<FilterResponseDto[]>([]);
const filteredGroups = ref<FilterResponseDto[]>([]);
//const selectedGroupSlug = ref<number | null>(null);
const loggedInUser = ref<User | null>(null);
const isApplyButtonDisabled = ref<boolean>(false);

const emit = defineEmits(['refresh-candidate-tags']);

// Watchers using the watch function
// eslint-disable-next-line @typescript-eslint/no-empty-function
watch(
  loadTags,
  (newValue, oldValue) => {
    loadCandidateTags();
  },
  { immediate: true }
);

async function loadCandidateTags() {
  try {
    loggedInUser.value = userModule.loggedInUser;
    candidateTagOptions.value = [];
    candidateTagOptions.value =
      await interviewRecruiterModule.dogetCandidateTagsWithGroup();
    copyTagOptions.value = JSON.parse(
      JSON.stringify(candidateTagOptions.value)
    );
    //if Admin retrieving group in tenant level else retrieving in user level
    if (isAdmin()) {
      await getTenantGroups();
    } else {
      await getUserGroups();
    }
  } catch (error) {
    console.log(error);
    if (
      (error as any)?.message &&
      (error as any).message.includes('TOKEN_ERROR')
    ) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast(t('message.failedProcessMsg') as string);
    }
  }
}

function onTagsFilterSearchChange() {
  if (tagsFilterSearch.value) {
    filteredTagOptions.value = copyTagOptions.value
      .map(group => {
        const matchingTags = group.candidateTagDtos.filter(tag =>
          tag.label.toLowerCase().match(tagsFilterSearch.value.toLowerCase())
        );
        return {
          ...group,
          candidateTagDtos: matchingTags
        };
      })
      .filter(group => group.candidateTagDtos.length > 0);
  } else {
    filteredTagOptions.value = copyTagOptions.value;
  }
  candidateTagOptions.value = filteredTagOptions.value;
}

const tagValueChanged = (tag: TagMangementDto) => {
  candidateTagOptions.value.forEach(e => {
    if (e.value === tag.value) {
      e.disableSave = false;
    }
  });
};

const createNewTag = async () => {
  try {
    showLoadingIndicator.value = true;
    let newTagDto: CandidateTagCreateAssignGroupRequest = {
      newTagName: newTagValue.value,
      groupSlugs: []
    };

    for (let index = 0; index < userGroups.value.length; index++) {
      const element = userGroups.value[index];
      if (element.selected) {
        newTagDto.groupSlugs.push(element.id);
      }
    }

    /*if (isAdmin() && selectedGroupSlug.value != null) {
        newTagDto.groupSlug = selectedGroupSlug.value;
      }*/
    await interviewRecruiterModule.doCreateCandidateTagAssignGroup(newTagDto);
    await loadCandidateTags();
    showAddTag.value = false;
    disableAddTag.value = false;
    //selectedGroupSlug.value  = null;
    showLoadingIndicator.value = false;
    emit('refresh-candidate-tags', {});
    showSuccessMsgToast(t('message.newTagCeatedMsgToast') as string);
    //clear tag entered after save
    newTagValue.value = '';
  } catch (error) {
    showLoadingIndicator.value = false;
    console.log(error);
    if (
      (error as any)?.message &&
      (error as any).message.includes('TOKEN_ERROR')
    ) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast(t('message.failedProcessMsg') as string);
    }
  }
};

const updateTagName = async (tag: TagMangementDto) => {
  try {
    await interviewRecruiterModule.doUpdateCandidateTagName(tag);
    candidateTagOptions.value.forEach(e => {
      if (e.value === tag.value) {
        e.disableSave = true;
      }
    });
    emit('refresh-candidate-tags', {});
    showSuccessMsgToast(t('message.changesSavedText') as string);
  } catch (error) {
    showLoadingIndicator.value = false;
    console.log(error);
    if (
      (error as any)?.message &&
      (error as any).message.includes('TOKEN_ERROR')
    ) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast(t('message.failedProcessMsg') as string);
    }
  }
};

const deleteTagName = async () => {
  try {
    if (selectedTagToDelete.value) {
      await interviewRecruiterModule.doDeleteCandidateTagName(
        selectedTagToDelete.value
      );
      await loadCandidateTags();
      selectedTagToDelete.value = null;
      showTagDeleteConfirmation.value = false;
      emit('refresh-candidate-tags', {});
      showSuccessMsgToast(t('message.tagDeletedMsgToast') as string);
    }
  } catch (error) {
    showLoadingIndicator.value = false;
    console.log(error);
    if (
      (error as any)?.message &&
      (error as any).message.includes('TOKEN_ERROR')
    ) {
      router.push({ name: 'home' });
    } else {
      showErrorMsgToast(t('message.failedProcessMsg') as string);
    }
  }
};

const getTenantGroups = async () => {
  const response = await interviewRecruiterModule.doGetTenantGroups();
  userGroups.value = response;
  filteredGroups.value = userGroups.value;
};

const getUserGroups = async () => {
  const response = await interviewRecruiterModule.doGetUserGroups();
  userGroups.value = response;
  filteredGroups.value = userGroups.value;
};

const onGroupFilterSearchChange = () => {
  userGroups.value = filteredGroups.value.filter(x =>
    x.itemName.toLowerCase().match(groupFiltersearch.value.toLowerCase())
  );
};

const onClickUserGroup = (group: FilterResponseDto) => {
  group.selected = !group.selected;
  selectUserGroup(group);
};

//Below method modified to enable multiple selection of Group
const selectUserGroup = (group: FilterResponseDto) => {
  const selectableUserGroupIndex: number = findIndex(
    userGroups.value,
    function (t) {
      return t.id == group.id;
    }
  );

  if (selectableUserGroupIndex !== -1) {
    userGroups.value[selectableUserGroupIndex].selected = group.selected;
  }
  if (isSelectedGroup()) {
    isApplyButtonDisabled.value = false;
  } else {
    isApplyButtonDisabled.value = true;
  }
};

const isAdmin = () => {
  if (loggedInUser.value != null) {
    if (loggedInUser.value.roleLevel.toString() === 'SUPERADMIN') {
      return true;
    } else if (loggedInUser.value.roleLevel.toString() === 'ADMIN') {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const disableSave = () => {
  // if (isAdmin()) {
  return newTagValue.value.length < 2 || !isSelectedGroup();
  // } else {
  //   return newTagValue.value.length < 2;
  // }
};

const isSelectedGroup = () => {
  //returns true if atleastone group selected
  return userGroups.value.some(item => item.selected);
};

const deselectGroup = () => {
  for (let index = 0; index < userGroups.value.length; index++) {
    const element = userGroups.value[index];
    if (element.selected) {
      userGroups.value[index].selected = false;
    }
  }
};

const getGroupTitle = (group: GroupCandidateTagDto) => {
  return group.groupName + ' (' + group.candidateTagDtos.length + ')';
};
</script>
