import { DefineComponent, defineAsyncComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import BlankLayout from '@/layouts/BlankLayout.vue';
import CenteredLayout from '@/layouts/CenteredLayout.vue';
import CompactLayout from '@/layouts/CompactLayout.vue';
import CorporateIdentityLayout from '@/layouts/CorporateIdentityLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import DisclaimerLayout from '@/layouts/DisclaimerLayout.vue';
import ExternalLayout from '@/layouts/ExternalLayout.vue';
import FullScreenLayout from '@/layouts/FullScreenLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import SmallLayout from '@/layouts/SmallLayout.vue';
import Home from '@/views/Home.vue';

// when adding a new path to the router, check FrontendPathsController in the backend application
// and add the path there also if not already covered. is needed to avoid 404 in some cases.
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component:   CenteredLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'home',
          component:  Home as unknown as DefineComponent
        },
        {
          path: '/newpassword/:token',
          name: 'newpassword',
          component: () => import('@/views/NewPassword.vue'),
          props: true
        },
        {
          name: 'invalidtenant',
          path: '/invalidtenant',
          component: () => import('@/views/InvalidTenant.vue')
        },
        {
          name: 'error404',
          path: '/404',
          component: () => import('@/views/Error404.vue')
        },
        {
          path: '/onboardnewtenant',
          name: 'onboardnewtenant',
          component: () => import('@/views/OnboardTenant.vue'),
          props: true
        },
        {
          path: '/sad/:guid',
          name: 'sadatslogin',
          component: () => import('@/views/SuperAdminATSLogin.vue'),
          props: true
        },
        {
          path: '/leadgen',
          name: 'leadgen',
          component: () => import('@/views/MetaLeadAd.vue'),
          props: true
        }
      ]
    },
    {
      path: '/interview/:interviewGuid',
      component:  DefaultLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'profile',
          component: () => import('@/views/Profile.vue')
        }
      ]
    },
    {
      path: '/share/:shareGuid',
      component:  ExternalLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'shareinterview',
          component: () => import('@/views/ShareInterview.vue'),
          props: true
        }
      ]
    },
    {
      path: '/extJobPick',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'extJobSelection',
          component: () => import('@/views/ExternalJobSelection.vue'),
          props: true
        }
      ]
    },
    {
      path: '/extTenantPick',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'extTenantSelection',
          component: () => import('@/views/ExternalTenantSelection.vue'),
          props: true
        }
      ]
    },
    {
      path: '/sales',
      component: ExternalLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'sales',
          component: () => import('@/views/Sales.vue'),
          props: true
        }
      ]
    },
    {
      path: '/recruiter',
     component: MainLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          redirect: '/recruiter/jobs'
        },
        {
          path: '/recruiter/dashboard',
          name: 'dashboard',
          component: defineAsyncComponent(() => import('@/views/Dashboard.vue')) // defineAsyncComponent supports lazy loading for dynamic imports and better type inference.
        },
        {
          path: '/recruiter/jobs',
          name: 'managejobs',
          component: defineAsyncComponent(() => import('@/views/JobsMain.vue')),
          props: true
        },
        {
          path: '/recruiter/jobs/interview/:interviewGuid',
          name: 'interviewrecruiter',
          component: defineAsyncComponent(() => import('@/views/InterviewRecruiter.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/jobs/dashboard/:jobSlug',
          name: 'jobdashboard',
          component: defineAsyncComponent(() => import('@/views/JobDashboard.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/jobs/dashboard/:jobSlug',
          name: 'postingdashboard',
          component: defineAsyncComponent(() => import('@/views/JobDashboard.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/jobs/administration/:jobSlug',
          name: 'jobadministration',
          component: defineAsyncComponent( () => import('@/views/JobAdministration.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/jobs/config/:jobSlug',
          name: 'jobconfig',
          component: defineAsyncComponent( () => import('@/views/JobConfiguration.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/campaigns/details/:campaignSlug',
          name: 'campaigndetails',
          component: defineAsyncComponent( () => import('@/views/CampaignDetails.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/adcampaigns/details/:referenceId',
          name: 'adcampaigndetails',
         component: defineAsyncComponent( () => import('@/views/AdCampaignInfo.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/campaigns/config/:campaignSlug',
          name: 'campaignconfig',
          component: defineAsyncComponent( () => import('@/views/CampaignConfig.vue')),
          meta: { toolbarName: 'managejobs' },
          props: true
        },
        {
          path: '/recruiter/masterdata',
          name: 'masterdata',
          component: defineAsyncComponent( () =>  import('@/views/MasterData.vue')),
        },
        {
          path: '/recruiter/admin',
          name: 'tenant_admin',
          component: defineAsyncComponent( () => import('@/views/TenantAdmin.vue')),
        },
        {
          path: '/superadmin/config',
          name: 'superadminconfig',
          component: defineAsyncComponent( () => import('@/views/SuperAdmin.vue')),
        },
        {
          path: '/recruiter/applicants',
          name: 'applicants',
          component: defineAsyncComponent( () => import('@/views/ApplicantSearch.vue')),
        },
        {
          path: '/recruiter/reports',
          name: 'reports',
          component: defineAsyncComponent( () => import('@/views/Reports.vue')),
        },
        {
          path: '/recruiter/questionnaire',
          name: 'recruiterquestionnaire',
          component: defineAsyncComponent( () => import('@/views/CampaignQuestionnaireWrapper.vue')),
        },
        {
          path: '/recruiter/reports/conversation',
          name: 'conversationreports',
         component: defineAsyncComponent( () => import('@/views/ConversationReports.vue')),
        },
        {
          path: '/recruiter/jobtemplatewizard/:jobTemplateSlug',
          name: 'jobtemplatewizard',
          component: defineAsyncComponent( ()=> import('@/views/JobTemplateWizard.vue')),
          props: true
        },
        {
          path: '/questionnaire',
          name: 'questionnaire',
          component: defineAsyncComponent( () =>  import('@/views/QuestionnaireWrapper.vue')),
        },
        {
          path: '/bulkmessages',
          name: 'bulkmessages',
          component: defineAsyncComponent( () => import('@/views/BulkMessage.vue')),
        },
        {
          path: '/bulkmessages/recipients/:bulkMessageSlug',
          name: 'bulkmessagerecipient',
          component: defineAsyncComponent( () => import('@/views/BulkMessageRecipient.vue')),
          props: true
        },
       {
          path: '/messagecenter',
          name: 'messagecenter',
         component: defineAsyncComponent(() => import('@/views/MessageCenter.vue')),
          props: true
        },
        {
          path: '/assessmentruns',
          name: 'assessmentruns',
         component: defineAsyncComponent( () => import('@/views/AssessmentRunWrapper.vue')),
        },
        {
          path: '/assessmentruns/details/:runSlug',
          name: 'assessmentrundetail',
          component: defineAsyncComponent( () => import('@/views/AssessmentRunDetail.vue')),
          props: true
        },
        {
          path: '/assessmentruns/:runSlug/:guid',
          name: 'assessmentrunprofile',
          component: defineAsyncComponent( () => import('@/views/AssessmentRunProfileWrapper.vue')),
          props: true
        },
        {
          path: '/contactsimport',
          name: 'contactsimport',
          component: defineAsyncComponent( () => import('@/views/ContactsImport.vue')),
        }
      ]
    },
    {
      path: '/whatsapp',
      component:  CorporateIdentityLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'whatsapp',
          component: () => import('@/views/Whatsapp.vue')
        }
      ]
    },
    {
      path: '/widget',
      component: SmallLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'widget',
          component: () => import('@/views/InterviewWidget.vue')
        }
      ]
    },
    {
      path: '/jobs',
      component: CompactLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'messenger',
          component: () => import('@/views/WebMessenger.vue'),
          props: true
        }
      ]
    },
    {
      path: '/ext/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'extshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true }
        }
      ]
    },
    {
      path: '/vb/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'vbapiextshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true, apicall: true, isviber: true }
        }
      ]
    },
    {
      path: '/vb-v2/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'disp-vbapiextshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true, apicall: true, isviber: true }
        }
      ]
    },
    {
      path: '/go/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'apiextshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true, apicall: true }
        }
      ]
    },
    {
      path: '/go-v2/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'disp-apiextshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true, apicall: true }
        }
      ]
    },
    {
      path: '/tc/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'tcapiextshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true, apicall: true, carrerpageorigin: 'TC' }
        }
      ]
    },
    {
      path: '/tc-v2/:guid',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'disp-tcapiextshorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { extcall: true, apicall: true, carrerpageorigin: 'TC' }
        }
      ]
    },
    {
      path: '/disclaimer',
      component: DisclaimerLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'disclaimer',
          component: () => import('@/views/Disclaimer.vue'),
          props: true
        }
      ]
    },
    {
      path: '/discl/',
      component: FullScreenLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'discl',
          component: () => import('@/views/DisclaimerDesign.vue'),
          props: true
        }
      ]
    },
    {
      path: '/viber_discl',
      component: FullScreenLayout as unknown as DefineComponent,
      children: [
        {
          path: '',
          name: 'viber_discl',
          component: () => import('@/views/ViberDisclaimer.vue'),
          props: true
        }
      ]
    },
    {
      path: '/',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '/:guid',
          name: 'shorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { internalGuid: true }
        }
      ]
    },
    {
      path: '/disp-redirect',
      component: BlankLayout as unknown as DefineComponent,
      children: [
        {
          path: '/disp-redirect/:guid',
          name: 'disp-shorturl',
          component: () => import('@/views/ShortUrl.vue'),
          props: true,
          meta: { internalGuid: true }
        }
      ]
    },
    {
      path: '/:catchAll(.*)',
      component: () => import('@/views/Error404.vue')
    }
    ]
  });

export default router;
