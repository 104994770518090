import script from "./MainLayout.vue?vue&type=script&setup=true&lang=ts"
export * from "./MainLayout.vue?vue&type=script&setup=true&lang=ts"

import "./MainLayout.vue?vue&type=style&index=0&id=38dbe8b2&lang=css"

const __exports__ = script;

export default __exports__
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import Ripple from 'quasar/src/directives/ripple/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QBtn,QMenu,QList,QItem,QItemSection,QIcon,QSeparator,QTabs,QTab,QBtnDropdown,QRouteTab,QItemLabel,QToggle,QTooltip,QBadge,QPageContainer,QDialog,QCard,QInfiniteScroll,QCardSection,QSpinner,QAvatar,QSpace,QInput,QCardActions,QSelect,QCheckbox});qInstall(script, 'directives', {ClosePopup,Ripple});
